import { useContext } from "react";
import {
   TableContainer,
   Table,
   TableHead,
   TableRow,
   TableCell,
   TableBody,
   Box,
   IconButton,
   Tooltip,
   Typography,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { getUrlS3 } from "../../lib/usersBEClient";
import AnnouncementIcon from "@mui/icons-material/Announcement";

interface DocumentsTableProps {
   index?: number;
   rows: any;
   disabled?: boolean;
}

export const DocumentsTable = (props: DocumentsTableProps) => {
   const { complaint, setFileName, setHeader, setSubSteps } = useContext(ComplaintStepperContext);

   const navigate = useNavigate();

   const validate = (documentName: string, resolution: boolean) => {
      if (
         complaint.documentation.some((doc) => {
            if (props.index && props.index !== 0) {
               const validation = doc.name === `${documentName}-${complaint.reportNumber}-${props.index}.pdf`;
               return validation;
            } else {
               const validation = doc.name === `${documentName}-${complaint.reportNumber}.pdf`;
               return validation;
            }
         })
      ) {
         return true;
      }
      if (documentName === `resolucion-${complaint.type || "denuncia"}` && resolution) {
         if (complaint?.type !== "denuncia") return false;
         return !complaint.newClasification;
      }
      return false;
   };

   const getFileSignedUrl = async (docmunetKey: string) => {
      const document =
         props.index && props.index !== 0
            ? `${docmunetKey}-${complaint.reportNumber}-${props.index}`
            : `${docmunetKey}-${complaint.reportNumber}`;
      const fileUrlResponse = await getUrlS3("files-lecosy", { folder: `docs/${complaint.reportNumber}` }, document);
      window.open(fileUrlResponse, "_blank");
   };

   return (
      <TableContainer>
         <Table>
            <TableHead>
               <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}> Nombre </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                     Documento
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                     Plantilla
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 600 }}>
                     Agregar
                  </TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {props.rows.map((e, i) => {
                  return (
                     <TableRow key={`${e}-${i}`}>
                        <TableCell>
                           {validate(e.documentName, false) ? (
                              <Box
                                 sx={{
                                    display: "flex",
                                    alignItems: "center",
                                 }}
                              >
                                 {e.document}
                                 <CheckCircleIcon color="success" sx={{ ml: 2 }} />
                              </Box>
                           ) : (
                              e.document
                           )}
                        </TableCell>
                        {!props.disabled ? (
                           <>
                              <TableCell align="center">
                                 {validate(e.documentName, false) ? (
                                    <IconButton onClick={() => getFileSignedUrl(e.documentName)}>
                                       <PictureAsPdfIcon />
                                    </IconButton>
                                 ) : null}
                              </TableCell>
                              {e.templateLink ? (
                                 <TableCell align="center">
                                    <IconButton
                                       onClick={() => navigate(e.templateLink)}
                                       disabled={validate(e.documentName, false)}
                                    >
                                       <DescriptionIcon />
                                    </IconButton>
                                 </TableCell>
                              ) : (
                                 <TableCell></TableCell>
                              )}
                              {e.documentName && !e.documentName.includes("notificacion") ? (
                                 <TableCell align="center">
                                    <IconButton
                                       disabled={validate(e.documentName, true)}
                                       onClick={() => {
                                          setFileName(e.documentName);
                                          setHeader(e.fileHeader);
                                          setSubSteps(1);
                                       }}
                                    >
                                       <UploadFileIcon />
                                    </IconButton>
                                 </TableCell>
                              ) : (
                                 <TableCell></TableCell>
                              )}
                           </>
                        ) : (
                           <TableCell colSpan={4} align="center">
                              <Tooltip
                                 title={
                                    <Typography fontSize={13}>
                                       Permisos limitados para gestionar la denuncia.
                                    </Typography>
                                 }
                                 placement="bottom"
                                 sx={{ fontSize: 16 }}
                              >
                                 <AnnouncementIcon sx={{ color: "#6A7F96" }} />
                              </Tooltip>
                           </TableCell>
                        )}
                     </TableRow>
                  );
               })}
            </TableBody>
         </Table>
      </TableContainer>
   );
};
