import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Button, Grid, Collapse } from "@mui/material";
import { managePlatformInfoSuggestions } from "../../../../lib/usersBEClient";
import { ClientConfigContext } from "../../../../context/client/clientConfigContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { ConfirmationModal } from "../../../ConfirmationModal";
import { Suggestion } from "./Suggestion";
import { NewSuggestion } from "./NewSuggestion";
interface SuggestionsModalProps {
   suggestions: string[];
   setSuggestions: Function;
   setSuggestionSeed: Function;
   onClose: Function;
   isOpen: boolean;
}

export const SuggestionsModal: React.FC<SuggestionsModalProps> = ({
   suggestions,
   setSuggestions,
   setSuggestionSeed,
   onClose,
   isOpen,
}) => {
   const [editingIndex, setEditingIndex] = useState<number | null>(null);
   const { showSnackBar } = useContext(SnackBarContext);
   const { clientInfo, refreshClientInfo } = useContext(ClientConfigContext);
   const [editingText, setEditingText] = useState<string>("");
   const [initialSuggestions, setInitialSuggestions] = useState<string[]>([]);
   const [bottomButtonHidden, setBottomButtonHidden] = useState<boolean>(true);
   const [hiddenButtonsAddCategorie, setHiddenButtonsAddCategorie] = useState<boolean>(false);
   const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
   const [addingNew, setAddingNew] = useState(false);

   useEffect(() => {
      if (isOpen) {
         setInitialSuggestions([...suggestions]);
      }
   }, [isOpen]);

   const handleModify = (index: number) => {
      setEditingIndex(index);
      setEditingText(suggestions[index] || "");
   };

   const handleDelete = (index: number) => {
      const newSuggestions = suggestions.filter((_, i) => i !== index);
      setSuggestions(newSuggestions);
   };

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditingText(e.target.value);
   };

   const handleSubmit = async () => {
      const foundService = clientInfo.company_details.servicesDetails?.find((service) => service?.details?.platformId)
         .details?.platformId;
      if (!foundService) return showSnackBar("Error al cargar servicio", true);
      const response = await managePlatformInfoSuggestions(foundService.subName, suggestions);
      if (!response) return showSnackBar("Error al guardar sugerencias", true);
      showSnackBar("Sugerencias guardadas correctamente", false);
      onClose();
      refreshClientInfo();
      setSuggestionSeed((s) => s + 1);
   };

   const handleSave = () => {
      if (editingIndex !== null) {
         const newSuggestions = [...suggestions];
         newSuggestions[editingIndex] = editingText;
         setSuggestions(newSuggestions);
         setEditingIndex(null);
      }
   };

   const handleAddSuggestion = (value) => {
      setSuggestions((s) => [...s, value]);
      setBottomButtonHidden(false);
      showSnackBar("Sugerencia agregada con éxito", false);
   };

   const handleCancel = () => {
      setSuggestions(initialSuggestions);
      onClose();
   };

   return (
      <>
         <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            height={"100%"}
            minWidth={"30%"}
         >
            <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
               <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  marginTop={4}
                  marginBottom={1}
                  sx={{ borderBottom: 1, borderColor: "lightGray", pb: 1 }}
               >
                  <Typography
                     variant="h6"
                     sx={{
                        pl: 3,
                        pr: 1,
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "1.1rem",
                     }}
                     align="center"
                  >
                     Categoria
                  </Typography>
                  <Box display={"flex"}>
                     <Typography
                        variant="h6"
                        sx={{
                           pl: 3,
                           pr: 1,
                           fontWeight: "600",
                           display: "flex",
                           alignItems: "center",
                           fontSize: "1.1rem",
                        }}
                        align="center"
                     >
                        Modificar
                     </Typography>
                     <Typography
                        variant="h6"
                        sx={{
                           pl: 3,
                           pr: 1,
                           fontWeight: "600",
                           display: "flex",
                           alignItems: "center",
                           fontSize: "1.1rem",
                        }}
                        align="center"
                     >
                        Eliminar
                     </Typography>
                  </Box>
               </Box>
               <Box
                  key={suggestions.length}
                  maxHeight={"310px"}
                  sx={{ overflowY: "auto", overflowX: "hidden", width: "auto" }}
               >
                  {suggestions.map((suggestion, index) => (
                     <Suggestion
                        key={suggestion}
                        suggestion={index === editingIndex ? editingText : suggestion}
                        onModify={() => handleModify(index)}
                        onDelete={() => handleDelete(index)}
                        onChange={handleChange}
                        onSave={handleSave}
                        hiddenButtons={setBottomButtonHidden}
                        setHiddenButtonsAddCategorie={setHiddenButtonsAddCategorie}
                        index={index}
                        suggestionList={suggestions}
                     />
                  ))}
                  <Collapse in={addingNew} unmountOnExit>
                     <NewSuggestion
                        suggestionList={suggestions}
                        handleAddSuggestion={handleAddSuggestion}
                        setAddingNew={setAddingNew}
                     />
                  </Collapse>
               </Box>
               {hiddenButtonsAddCategorie ||
                  (!addingNew && (
                     <Box display={"flex"} justifyContent={"flex-end"} pt={1} pr={4}>
                        <Button
                           sx={{
                              p: 0,
                              backgroundColor: "#162c44",
                              color: "white",
                              width: "180px",
                              height: "30px",
                              ":hover": { backgroundColor: "#162c44" },
                           }}
                           onClick={() => setAddingNew(true)}
                        >
                           <AddCircleIcon sx={{ color: "white", mr: 1 }} />
                           Agregar categoría
                        </Button>
                     </Box>
                  ))}
            </Box>
            {bottomButtonHidden === false && (
               <Grid container>
                  <Box
                     display={"flex"}
                     justifyContent={"flex-end"}
                     bgcolor={"#E9EDF5"}
                     p={1.5}
                     sx={{ borderRadius: "0px 0px 10px 10px" }}
                     width={"100%"}
                  >
                     <Button onClick={handleCancel} sx={{ fontWeight: "bold", fontSize: "16px" }}>
                        Cancelar
                     </Button>
                     <Button
                        onClick={() => setOpenConfirmationModal(true)}
                        sx={{ fontWeight: "bold", fontSize: "16px" }}
                     >
                        Guardar cambios
                     </Button>
                  </Box>
               </Grid>
            )}
         </Box>
         <ConfirmationModal
            onConfirm={handleSubmit}
            open={openConfirmationModal}
            setOpen={setOpenConfirmationModal}
            generic
         />
      </>
   );
};
