import { createContext, useState, useContext } from "react";
import { deleteDocument, getComplaintsByReportNumber, getComplaintStateTracking } from "../lib/lecosyBackendClient";
import { IComplaints, IComplaintState, WitnessesInterface, IDocumentationDetails } from "../types/BaseTypes";
import { SnackBarContext } from "./snackBarContext";

type ComplaintStepperContextInterface = {
   complaint: IComplaints;
   getComplaint: Function;
   complaintState: IComplaintState;
   setComplaintState: Function;
   documentation: IDocumentationDetails[];

   selectedData: any;
   setSelectedData: Function;
   checkDocuments: Function;
   // witnesses
   witnesses: WitnessesInterface[];
   selectedWitness: any;
   setSelectedWitness: Function;
   getWitnesses: Function;
   getFilterWitnesses: Function;
   filteredWitnesses: WitnessesInterface[];
   // stepper variables
   fileName: string;
   setFileName: Function;

   // fileToUpload: IBlob | null;
   fileToUpload: File | null;
   setFileToUpload: Function;

   subSteps: number;
   setSubSteps: Function;
   header: string;
   setHeader: Function;

   dataState: number;
   setDataState: Function;

   isLoading: boolean;
   setIsLoading: Function;

   isLastStep: Function;
   isLastStepResolution: Function;

   openCompleteDialog: boolean;
   setOpenCompleteDialog: Function;
   openCompleteAllSteps: boolean;
   setOpenCompleteAllSteps: Function;

   replicaOpen: boolean;
   setReplicaOpen: Function;
   replicaSubStep: boolean;
   setReplicaSubStep: Function;

   getFilteredReplica: Function;
   deleteDocumentFile: Function;
};

const initialComplaint = {
   type: "",
   pseudonym: "",
   directlyAffected: false,
   followUpEmail: "",
   present: false,
   reportNumber: "",
   complaintType: "",
   complainerType: "",
   clasification: "",
   category: "",
   body: "",
   date: new Date(),
   documentation: [
      {
         name: "",
         signature: [
            {
               member: "",
               signed: false,
            },
         ],
      },
   ],
   createdAt: new Date(),
   sentDate: new Date(),
   witnesses: [
      {
         complaint: "",
         gender: "",
         observations: "",
         _id: "",
         aditionalInformation: { email: "", phoneNumber: "" },
         evidence: [],
      },
   ],
   status: "",
   anonimousEvidence: [],
   complainerDetails: {
      complaint: "",
      name: "",
      lastName: "",
      gender: "",
      phoneNumber: "",
      email: "",
      address: "",
      zipCode: "",
      country: "",
      state: "",
      city: "",
   },
   offenderDetails: [
      {
         complaint: "",
         gender: "",
         observations: "",
         _id: "",
         aditionalInformation: { email: "", phoneNumber: "" },
         evidence: [],
      },
   ],
   companyBranch: { name: "" },
   intern: true,
   observations: "",
   resolution: "",
   rejectedDate: new Date(),
   specificPlace: "",
   newClasification: "",
};

const steps = ["Incompetencia", "Testigos", "Denunciado", "Denunciante"];
const stepsResolution = ["Resolución", "Notificación de resolución", "Cierre de expediente"];

export const ComplaintStepperContext = createContext<ComplaintStepperContextInterface>({
   //Complaint
   complaint: initialComplaint,

   documentation: [
      {
         name: "",
         signature: [
            {
               member: "",
               signed: false,
            },
         ],
      },
   ],
   getComplaint: () => {},
   complaintState: {
      processState: 0,
      stateTracking: 0,
   },
   setComplaintState: () => {},
   selectedData: {
      e: {
         name: "",
         lastName: "",
         gender: "",
         charge: "",
         observation: "",
         _id: "",
      },
      index: 0,
   },
   setSelectedData: () => {},
   // Witnesses
   witnesses: [
      { complaint: "", gender: "", observations: "", _id: "", aditionalInformation: { email: "", phoneNumber: "" } },
   ],
   selectedWitness: {
      complaint: "",
      gender: "",
      observations: "",
      _id: "",
      aditionalInformation: { email: "", phoneNumber: "" },
   },
   setSelectedWitness: () => {},
   getWitnesses: () => {},
   getFilterWitnesses: () => {},
   filteredWitnesses: [
      { complaint: "", gender: "", observations: "", _id: "", aditionalInformation: { email: "", phoneNumber: "" } },
   ],
   // Upload file
   header: "",
   setHeader: () => {},
   fileName: "",
   setFileName: () => {},
   fileToUpload: null,
   setFileToUpload: () => {},
   checkDocuments: () => {},
   // Stepper variables
   subSteps: 0,
   setSubSteps: () => {},
   dataState: 0,
   setDataState: () => {},
   isLoading: true,
   setIsLoading: () => {},
   isLastStep: () => {},
   isLastStepResolution: () => {},
   // Modal
   openCompleteDialog: true,
   setOpenCompleteDialog: () => {},
   openCompleteAllSteps: true,
   setOpenCompleteAllSteps: () => {},

   // Replica
   replicaOpen: false,
   setReplicaOpen: () => {},
   replicaSubStep: false,
   setReplicaSubStep: () => {},

   getFilteredReplica: () => {},
   deleteDocumentFile: () => {},
});

export const ComplaintStepperProvider = ({ children }) => {
   const [complaint, setComplaint] = useState<IComplaints>(initialComplaint);
   const [documentation, setDocumentation] = useState<IDocumentationDetails[]>([]);
   const [complaintState, setComplaintState] = useState<IComplaintState>({
      processState: 0,
      stateTracking: 0,
   });
   const [selectedData, setSelectedData] = useState({
      e: {
         name: "",
         lastName: "",
         gender: "",
         charge: "",
         observation: "",
         _id: "",
      },
      index: 0,
   });
   //Witnesses data
   const [witnesses, setWitnesses] = useState<WitnessesInterface[]>([]);
   const [selectedWitness, setSelectedWitness] = useState<WitnessesInterface | null>(null);
   const [filteredWitnesses, setFilteredWitnesses] = useState<WitnessesInterface[]>([]);
   //Stepper variables
   const [fileName, setFileName] = useState<string>("");
   const [subSteps, setSubSteps] = useState<number>(0);
   const [header, setHeader] = useState<string>("");
   const [dataState, setDataState] = useState<number>(0);
   const [isLoading, setIsLoading] = useState<boolean>(true);
   const [fileToUpload, setFileToUpload] = useState<File | null>(null);
   //Modal variables
   const [openCompleteDialog, setOpenCompleteDialog] = useState<boolean>(false);
   const [openCompleteAllSteps, setOpenCompleteAllSteps] = useState<boolean>(false);
   //Replica
   const [replicaOpen, setReplicaOpen] = useState<boolean>(false);
   const [replicaSubStep, setReplicaSubStep] = useState<boolean>(false);

   const { showSnackBar } = useContext(SnackBarContext);

   //Complaint Functions
   const getComplaint = async (reportNumber: string) => {
      try {
         const fetchComplaintInfo = await getComplaintsByReportNumber(reportNumber);
         setComplaint(fetchComplaintInfo);
         setWitnesses(fetchComplaintInfo.witnesses);
         setDocumentation(fetchComplaintInfo.documentation);
         fetchComplaintStateTracking(fetchComplaintInfo.reportNumber);
         setIsLoading(false);
      } catch (error: any) {
         setIsLoading(false);
      }
   };

   const getFilteredReplica = async (reportNumber: string) => {
      const filter = await getWitnesses(reportNumber).then((e) => {
         return e.filter((witnessesa) => witnessesa.createdBy === selectedData.e._id);
      });
      setFilteredWitnesses(filter);
   };

   const fetchComplaintStateTracking = async (reportNumber: string) => {
      const complaintStatusResponse = await getComplaintStateTracking(reportNumber);
      const complaintStatusDataObtained = complaintStatusResponse.data;
      setComplaintState(complaintStatusDataObtained);
      setIsLoading(false);
   };

   //Witnesses Functions
   const getWitnesses = async (reportNumber: string) => {
      const fetchComplaintInfo = await getComplaintsByReportNumber(reportNumber);
      return fetchComplaintInfo.witnesses;
   };

   const getFilterWitnesses = (offender: string) => {
      setFilteredWitnesses(witnesses.filter((witness) => witness.createdBy === offender));
   };

   //Check if necessary documents are up
   const checkDocuments = (involved: string) => {
      const necessaryDocs: boolean[] = [];
      let result = true;
      let involvedDocs = 1;
      if (involved === "testigo") {
         involvedDocs = witnesses.length === 0 ? 1 : witnesses.length;
      }
      if (involved === "denunciado") {
         involvedDocs = complaint.offenderDetails.length === 0 ? 1 : complaint.offenderDetails.length;
      }
      if (involved === "replica") {
         involvedDocs = filteredWitnesses.length;
      }
      for (let i = 0; i < involvedDocs; i++) {
         necessaryDocs.push(
            !!documentation.find((doc) =>
               involved === "resolution"
                  ? doc.name === `resolucion-denuncia-${complaint.reportNumber}.pdf` ||
                    doc.name === `resolucion-${complaint?.type}-${complaint.reportNumber}.pdf`
                  : involved === "notification"
                  ? doc.name ===
                    `notificacion-resolucion-${complaint?.type || "denuncia"}-${complaint.reportNumber}.pdf`
                  : involved === "cierre"
                  ? doc.name === `cierre-${complaint.reportNumber}.pdf`
                  : involved === "replica"
                  ? doc.name === `constancia-incomparecencia-testigo-${complaint.reportNumber}-1-${i + 1}.pdf` ||
                    doc.name === `constancia-incomparecencia-testigo-${complaint.reportNumber}-2-${i + 1}.pdf` ||
                    doc.name === `entrevista-testigo-${complaint.reportNumber}-1-${i + 1}.pdf` ||
                    doc.name === `entrevista-testigo-${complaint.reportNumber}-2-${i + 1}.pdf` ||
                    doc.name === `constancia-inexistencia-testigo-${complaint.reportNumber}-1-${i + 1}.pdf` ||
                    doc.name === `constancia-inexistencia-testigo-${complaint.reportNumber}-2-${i + 1}.pdf`
                  : doc.name === `constancia-incomparecencia-${involved}-${complaint.reportNumber}.pdf` ||
                    doc.name === `constancia-incomparecencia-${involved}-${complaint.reportNumber}-${i + 1}.pdf` ||
                    doc.name === `entrevista-${involved}-${complaint.reportNumber}.pdf` ||
                    doc.name === `entrevista-${involved}-${complaint.reportNumber}-${i + 1}.pdf` ||
                    doc.name === `constancia-inexistencia-${involved}-${complaint.reportNumber}.pdf` ||
                    doc.name === `constancia-inexistencia-${involved}-${complaint.reportNumber}-${i + 1}.pdf` ||
                    doc.name === `constancia-anonimo-${complaint.reportNumber}.pdf`
            )
         );
      }
      if (involved === "denunciado") {
         const replicaDocs: any[] = [];
         let replicaResult = false;
         for (let i = 0; i < involvedDocs; i++) {
            replicaDocs.push(
               documentation.find((doc) => doc.name === `replica-${complaint.reportNumber}-${i + 1}.pdf`)
            );
         }
         replicaDocs.forEach((involvedDoc) => {
            if (involvedDoc) {
               replicaResult = true;
            }
         });
         if (replicaResult) result = checkDocuments("replica");
      }

      necessaryDocs.forEach((involvedDoc) => {
         if (!involvedDoc) {
            result = false;
         }
      });
      return result;
   };

   const deleteDocumentFile = async (documentName: string) => {
      try {
         await deleteDocument(documentName, complaint.reportNumber);
         getComplaint(complaint.reportNumber);
         showSnackBar("El documento fue borrado.", false);
      } catch (error) {
         console.log(error);
         showSnackBar("Ocurrió un error, favor de reintentar.", true);
      }
   };

   //Steper Functions
   const isLastStep = () => {
      return complaintState.processState === steps.length - 1;
   };

   const isLastStepResolution = () => {
      return complaintState.processState === stepsResolution.length - 1;
   };

   return (
      <ComplaintStepperContext.Provider
         value={{
            complaint,
            getComplaint,
            complaintState,
            setComplaintState,
            checkDocuments,
            witnesses,
            selectedData,
            setSelectedData,
            selectedWitness,
            setSelectedWitness,
            getWitnesses,
            getFilterWitnesses,
            filteredWitnesses,
            fileName,
            setFileName,
            fileToUpload,
            setFileToUpload,
            subSteps,
            setSubSteps,
            header,
            setHeader,
            dataState,
            setDataState,
            documentation,
            isLoading,
            setIsLoading,
            isLastStep,
            isLastStepResolution,
            openCompleteDialog,
            setOpenCompleteDialog,
            openCompleteAllSteps,
            setOpenCompleteAllSteps,
            replicaOpen,
            setReplicaOpen,
            replicaSubStep,
            setReplicaSubStep,
            getFilteredReplica,
            deleteDocumentFile,
         }}
      >
         {children}
      </ComplaintStepperContext.Provider>
   );
};
