import {
   capitalizeFirstLetter,
   destructuredLocaleDateString,
   getDateFromISODate,
   getMonthInSpanish,
   getMonthWord,
   lecosyLogoBase64,
   LocaleDateString,
} from "../../const/globalConst";
import { header } from "../../const/templatesConst";

export const ddInterposicionDeDenuncia = (
   clasification: string,
   reportNumber: string,
   complainerName: string,
   offenderDetails: any[],
   complaintType: string,
   category: string,
   address: string,
   complainerType: string,
   directlyAffected: boolean,
   complainerPhoneNumber: string,
   complainerEmail: string,
   witnesses: any[],
   date: Date,
   specificPlace: string,
   body: string,
   status: string,
   observations: string,
   lecosyClasification: string,
   creationDate: string,
   type: string
) => {
   const witnessesTable = witnesses.map((e, i) => {
      return [
         {
            text: `Testigo ${i + 1}:`,
            bold: true,
            fontSize: 12,
            alignment: "right",
            margin: [0, 20, 0, 20],
         },
         [
            {
               text: [
                  {
                     text: "Nombre: ",
                     bold: true,
                     fontSize: 11,
                  },
                  {
                     text: e.name,
                     alignment: "left",
                     fontSize: 11,
                  },
               ],
               margin: [10, 1, 30, 1],
            },

            {
               text: [
                  {
                     text: "Puesto: ",
                     bold: true,
                     fontSize: 11,
                  },
                  {
                     text: e.position,
                     alignment: "left",
                     fontSize: 11,
                  },
               ],
               margin: [10, 1, 0, 1],
            },
            {
               text: [
                  {
                     text: "Género: ",
                     bold: true,
                     fontSize: 11,
                  },
                  {
                     text: e.gender,
                     alignment: "left",
                     fontSize: 11,
                  },
               ],
               margin: [10, 1, 0, 1],
            },

            {
               text: [
                  {
                     text: "Observaciones: ",
                     bold: true,
                     fontSize: 11,
                  },
                  {
                     text: e.observations,
                     alignment: "justify",
                     fontSize: 11,
                  },
               ],
               margin: [10, 1, 30, 1],
            },
         ],
      ];
   });

   const test =
      offenderDetails.length > 0 &&
      offenderDetails.map((e, i) => {
         return [
            {
               text: `Denunciado ${i + 1}:`,
               bold: true,
               fontSize: 12,
               alignment: "right",
               margin: [0, 20, 0, 20],
            },
            [
               {
                  text: [
                     {
                        text: "Nombre: ",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: e.name,
                        alignment: "left",
                        fontSize: 11,
                     },
                  ],
                  margin: [10, 1, 30, 1],
               },
               {
                  text: [
                     {
                        text: "Puesto: ",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: e.position,
                        alignment: "left",
                        fontSize: 11,
                     },
                  ],
                  margin: [10, 1, 0, 1],
               },
               {
                  text: [
                     {
                        text: "Género: ",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: e.gender,
                        alignment: "left",
                        fontSize: 11,
                     },
                  ],
                  margin: [10, 1, 0, 1],
               },

               {
                  text: [
                     {
                        text: "Observaciones: ",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: e.observations,
                        alignment: "justify",
                        fontSize: 11,
                     },
                  ],
                  margin: [10, 1, 30, 1],
               },
            ],
         ];
      });
   const InterpocisionDD = {
      info: {
         title: `interposicion-${reportNumber}`,
      },
      pageSize: "A4",
      pageMargins: [70, 90, 70, 160],
      header: header(`ACUSE DE INTERPOSICIÓN DE ${type.toUpperCase() || "DENUNCIA"}`),
      content: [
         {
            margin: [180, 15, 0, 0],
            table: {
               widths: [140, 200],
               body: [
                  [
                     { margin: [5, 5, 0, 0], text: "Calificada como:", fillColor: "#F1F1F1", bold: true, fontSize: 11 },
                     { margin: [0, 5, 5, 0], text: clasification, fillColor: "#F1F1F1", fontSize: 11 },
                  ],
                  [
                     {
                        margin: [5, 0, 0, 0],
                        text: "Número de reporte:",
                        fillColor: "#F1F1F1",
                        bold: true,
                        fontSize: 11,
                     },
                     { margin: [0, 0, 5, 0], text: reportNumber, fillColor: "#F1F1F1", fontSize: 11 },
                  ],
                  [
                     {
                        margin: [5, 0, 0, 0],
                        text: `Nombre del ${
                           type === "denuncia" ? "denunciante" : type === "queja" ? "quejoso" : "sugeridor"
                        }:`,
                        fillColor: "#F1F1F1",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        margin: [0, 0, 5, 0],
                        text: complainerName,
                        fillColor: "#F1F1F1",
                        fontSize: 11,
                     },
                  ],
                  [
                     {
                        margin: [5, 0, 0, 0],
                        text: `${capitalizeFirstLetter(type)} anónima:`,
                        fillColor: "#F1F1F1",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        margin: [0, 0, 5, 0],
                        text: complaintType === "anonima" ? "Si" : "No",
                        fillColor: "#F1F1F1",
                        fontSize: 11,
                     },
                  ],
                  (category && [
                     { margin: [5, 0, 0, 5], text: "Categoría:", fillColor: "#F1F1F1", bold: true, fontSize: 11 },
                     {
                        margin: [0, 0, 5, 5],
                        text: category,
                        fillColor: "#F1F1F1",
                        fontSize: 11,
                     },
                  ]) || [{}, {}],
               ],
            },
            layout: "noBorders",
         },
         {
            text: " DATOS DEL DENUNCIANTE O QUEJOSO ",
            background: "#2D4357",
            color: "#FFFFFF",
            bold: true,
            fontSize: 16,
            margin: [5, 20, 0, 5],
         },
         {
            text: [
               {
                  text: "Nombre: ",
                  bold: true,
                  fontSize: 11,
               },
               {
                  text: complainerName,
                  alignment: "left",
                  fontSize: 11,
               },
            ],
            margin: [25, 1, 25, 1],
         },
         {
            text: [
               {
                  text: "Domicilio: ",
                  bold: true,
                  fontSize: 11,
               },
               {
                  text: address,
                  alignment: "left",
                  fontSize: 11,
               },
            ],
            margin: [25, 1, 25, 1],
         },
         {
            text: [
               {
                  text: "Tipo de denunciante: ",
                  bold: true,
                  fontSize: 11,
               },
               {
                  text: complainerType,
                  alignment: "left",
                  fontSize: 11,
               },
            ],
            margin: [25, 1, 25, 1],
         },
         (directlyAffected && {
            text: [
               {
                  text: "Se le afectó directamente: ",
                  bold: true,
                  fontSize: 11,
               },
               {
                  text: directlyAffected ? "Si" : "No",
                  alignment: "left",
                  fontSize: 11,
               },
            ],
            margin: [25, 1, 25, 1],
         }) ||
            {},
         {
            text: "Medios de contacto:",
            bold: true,
            margin: [25, 1, 25, 1],
         },
         {
            columns: [
               {
                  text: [
                     {
                        text: "Número telefónico: ",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: complainerPhoneNumber,
                        alignment: "left",
                        fontSize: 11,
                     },
                  ],
                  margin: [25, 1, 0, 1],
               },
               {
                  text: [
                     {
                        text: "Correo electrónico: ",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: complainerEmail,
                        alignment: "left",
                        fontSize: 11,
                     },
                  ],
                  margin: [0, 1, 25, 1],
               },
            ],
         },
         (type === "denuncia" && {
            text: offenderDetails.length > 1 ? " DATOS DE LOS DENUNCIADOS " : " DATOS DEL DENUNCIADO ",
            background: "#2D4357",
            color: "#FFFFFF",
            bold: true,
            fontSize: 16,
            margin: [5, 15, 0, 5],
         }) ||
            {},
         type === "denuncia" && offenderDetails.length > 1
            ? {
                 layout: "noBorders",
                 table: {
                    widths: [100, 350],
                    body: test,
                 },
                 margin: [25, 0, 25, 0],
              }
            : {
                 text: "No aplica",
                 fontSize: 12,
                 bold: true,
                 margin: [25, 6, 25, 4],
              },
         (type === "denuncia" && {
            text: witnesses.length > 1 ? " DATOS DE LOS TESTIGOS " : " DATOS DEL TESTIGO ",
            background: "#2D4357",
            color: "#FFFFFF",
            bold: true,
            fontSize: 16,
            margin: [5, 15, 0, 5],
         }) ||
            {},
         type === "denuncia" && witnesses.length > 1
            ? {
                 layout: "noBorders",
                 table: {
                    widths: [100, 350],
                    body: witnessesTable,
                 },
                 margin: [25, 0, 25, 0],
              }
            : {
                 text: "No aplica",
                 fontSize: 12,
                 bold: true,
                 margin: [25, 6, 25, 4],
              },
         {
            text: ` DATOS DE LA ${type.toUpperCase()} `,
            background: "#2D4357",
            color: "#FFFFFF",
            bold: true,
            fontSize: 16,
            margin: [5, 15, 0, 5],
         },
         (date && {
            text: [
               {
                  text: "Fecha del acto: ",
                  bold: true,
                  fontSize: 11,
               },
               {
                  text: `${getDateFromISODate(date)}`,
                  alignment: "left",
                  fontSize: 11,
               },
            ],
            margin: [25, 1, 25, 1],
         }) ||
            {},
         {
            text: [
               {
                  text: "Ubicación donde sucedieron los hechos: ",
                  bold: true,
                  fontSize: 11,
               },
               specificPlace
                  ? {
                       text: specificPlace,
                       alignment: "left",
                       fontSize: 11,
                    }
                  : {
                       text: "No aplica",
                       alignment: "left",
                       fontSize: 11,
                    },
            ],
            margin: [25, 1, 25, 1],
         },
         {
            text: [
               {
                  text: "Descripción de la denuncia: ",
                  bold: true,
                  fontSize: 11,
               },
               {
                  text: body,
                  alignment: "justify",
                  fontSize: 11,
               },
            ],
            margin: [25, 1, 25, 1],
         },
         {
            text: " REPORTE LECOSY ",
            background: "#2D4357",
            color: "#FFFFFF",
            bold: true,
            fontSize: 16,
            margin: [5, 15, 0, 5],
         },
         {
            text: [
               {
                  text: "Estatus: ",
                  bold: true,
                  fontSize: 11,
               },
               {
                  text: status,
                  alignment: "left",
                  fontSize: 11,
               },
            ],
            margin: [25, 1, 25, 1],
         },
         observations && {
            text: [
               {
                  text: "Observaciones: ",
                  bold: true,
                  fontSize: 11,
               },
               {
                  text: observations === "" ? "No aplica" : observations,
                  alignment: "justify",
                  fontSize: 11,
               },
            ],
            margin: [25, 1, 25, 1],
         },
      ],
      footer: {
         columns: [
            {
               margin: [132.5, 0, 132.5, 0],
               table: {
                  hegiths: [30, 50],
                  widths: [330],
                  body: [
                     lecosyClasification && [
                        {
                           margin: [10, 5],
                           text: "Clasificación de hecho:",
                           fontSize: 11,
                           bold: true,
                           fillColor: "#dddddd",
                        },
                     ],
                     lecosyClasification && [
                        {
                           margin: [10, 5],
                           text: lecosyClasification,
                           fontSize: 11,
                           fillColor: "#dddddd",
                        },
                     ],
                     [
                        {
                           text: `Fecha de interposición de la denuncia en el canal: ${getDateFromISODate(
                              creationDate
                           )}`,
                           alignment: "center",
                           fontSize: 11,
                           margin: [0, 8, 0, 0],
                        },
                     ],
                  ],
               },
               layout: "noBorders",
            },
         ],
      },
   };
   return InterpocisionDD;
};

export const declinedComplaint = (
   city: string,
   state: string,
   company: string,
   createdAt: Date,
   observations: string,
   type: string
) => {
   const date = new Date();
   const createdAtDate = new Date(createdAt);
   const actualDateArray = date.toDateString().split(" ");
   const actualMonth = getMonthInSpanish(actualDateArray[1]);
   const complaintDate = destructuredLocaleDateString(new Date(createdAt));
   const declinedComplaintDD = {
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      content: [
         {
            canvas: [
               {
                  type: "rect",
                  x: 60,
                  y: 50,
                  w: 400,
                  h: 3.5,
                  color: "#152c44",
               },
            ],
         },
         {
            image: lecosyLogoBase64,
            width: 100,
            absolutePosition: { x: 250, y: 45 },
         },

         {
            text: `${type === "denuncia" ? "DENUNCIA" : type === "queja" ? "QUEJA" : "SUGERENCIA"} IMPROCEDENTE`,
            bold: true,
            fontSize: 16,
            alignment: "center",
            color: "#152c44",
            margin: [0, 50, 0, 10],
         },
         {
            text: [
               { text: "En la ciudad de " },
               { text: `${city}, ${state}` },
               { text: " siendo las " },
               { text: `${date.getHours()}:${date.getUTCMinutes()}` },
               { text: " horas del día " },
               { text: `${date.getDate()}` },
               { text: " del mes " },
               { text: `${actualMonth}` },
               { text: " del año " },
               { text: `${date.getFullYear()} ` },
               { text: "la organización " },
               { text: `${company}` },
               { text: " hace de su conocimiento que su denuncia interpuesta el día " },
               { text: `${createdAtDate.getDate()}` },
               { text: " de " },
               { text: `${complaintDate.month}` },
               { text: " del " },
               { text: `${createdAtDate.getFullYear()}` },
               {
                  text: " fue recibida y calificada de improcedente, por asi encuadrar en el siguiente causal de improcedencia: ",
               },
               { text: `${observations}` },
            ],
            fontSize: 11,
            alignment: "justify",
            margin: [45, 50, 45, 0],
            lineHeight: 1.1,
         },
      ],
   };
   return declinedComplaintDD;
};

export const proceededComplaint = (
   city: string,
   state: string,
   company: string,
   createdAt: Date,
   reportNumber: string,
   type: string
) => {
   const date = new Date();
   const actualDateArray = date.toDateString().split(" ");
   const actualMonth = getMonthInSpanish(actualDateArray[1]);
   const complaintDate = destructuredLocaleDateString(new Date(createdAt));
   const createdAtDate = new Date(createdAt);

   const proceededComplaintDD = {
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      content: [
         {
            canvas: [
               {
                  type: "rect",
                  x: 60,
                  y: 50,
                  w: 400,
                  h: 3.5,
                  color: "#152c44",
               },
            ],
         },
         {
            image: lecosyLogoBase64,
            width: 100,
            absolutePosition: { x: 250, y: 45 },
         },
         {
            text: `${type === "denuncia" ? "DENUNCIA" : type === "queja" ? "QUEJA" : "SUGERENCIA"} PROCEDENTE`,
            bold: true,
            fontSize: 16,
            alignment: "center",
            color: "#152c44",
            margin: [0, 50, 0, 10],
         },
         {
            text: [
               { text: "En la ciudad de " },
               { text: `${city}, ${state}` },
               { text: " siendo las " },
               { text: `${date.getHours()}:${date.getUTCMinutes()}` },
               { text: " horas del día " },
               { text: `${date.getDate()}` },
               { text: " del mes " },
               { text: `${actualMonth}` },
               { text: " del año " },
               { text: `${date.getFullYear()}` },
               { text: " la organización " },
               { text: `${company}` },
               { text: " hace de su conocimiento que su denuncia interpuesta el día " },
               { text: `${createdAtDate.getDate()}` },
               { text: " de " },
               { text: `${complaintDate.month}` },
               { text: " del " },
               { text: `${createdAtDate.getFullYear()}` },
               { text: " fue recibida y calificada de procedente, por lo cual se le asignó el número de reporte: " },
               { text: `${reportNumber}` },
            ],
            fontSize: 11,
            alignment: "justify",
            margin: [45, 50, 45, 0],
            lineHeight: 1.1,
         },
      ],
   };
   return proceededComplaintDD;
};
