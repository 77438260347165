import { Box, IconButton, TextField } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";

export const NewSuggestion = (props) => {
   const { suggestionList, handleAddSuggestion, setAddingNew } = props;
   const [error, setError] = useState<string>("");
   const [newValue, setNewValue] = useState("");

   const handleCheckClick = () => {
      if (newValue.trim() === "") {
         setError("El campo no puede estar vacío");
         return false;
      }
      if (suggestionList.includes(newValue)) {
         setError("La sugerencia ya existe");
         return false;
      }
      return true;
   };

   const handleChange = (e) => {
      setNewValue(e.target.value);
      setError("");
   };

   return (
      <Box
         display={"flex"}
         justifyContent={"space-between"}
         flexWrap={"wrap"}
         sx={{
            borderBottom: 1,
            borderColor: "lightGray",
            py: 0.5,
            display: "flex",
            pointerEvents: "auto",
            opacity: 1,
         }}
      >
         <TextField
            value={newValue}
            onChange={handleChange}
            autoFocus
            size="small"
            sx={{ pl: 3 }}
            error={!!error}
            helperText={error}
            inputProps={{ maxLength: 200 }}
         />
         <Box>
            <IconButton
               onClick={() => {
                  const validation = handleCheckClick();
                  if (validation) {
                     handleAddSuggestion(newValue);
                     setAddingNew(false);
                  }
               }}
               sx={{ mt: 0.5, mr: 8.4 }}
            >
               <CheckIcon sx={{ color: "#162c44", fontSize: 25, stroke: "#162c44", strokeWidth: 2 }} />
            </IconButton>
            <IconButton sx={{ mt: 0.5, mr: 3.2 }} onClick={() => setAddingNew(false)}>
               <DeleteForeverIcon sx={{ color: "#162c44" }} />
            </IconButton>
         </Box>
      </Box>
   );
};
