import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import { DocumentsTable } from "../DocumentsTable";
import { UserContext } from "../../../context/UserContext";

export const ResolutionStep = () => {
   const { complaint, setOpenCompleteDialog, checkDocuments } = useContext(ComplaintStepperContext);
   const { GetPermission } = useContext(UserContext);
   const access = GetPermission("Denuncias internas");

   return (
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "50vh" }}>
         <Box sx={{ flex: 2 }}>
            <DocumentsTable
               rows={[
                  {
                     document: complaint?.type !== "denuncia" ? "Resolución de la queja" : "Resolución de la denuncia",
                     templateLink: complaint?.type !== "denuncia" ? `resolucion-queja` : `acuse-resolucion`,
                     documentName: `resolucion-${complaint.type || "denuncia"}`,
                     fileHeader:
                        complaint.category === "Quejas"
                           ? "Agregar documento de resolución de la queja"
                           : "Agregar documento de resolución de la denuncia",
                  },
               ]}
               disabled={access}
            />
         </Box>
         {!access ? (
            <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mr: 2 }}>
               <Box sx={{ flex: "1 1 auto" }} />
               <Button
                  disabled={!checkDocuments("resolution")}
                  onClick={() => setOpenCompleteDialog(true)}
                  variant="outlined"
               >
                  {`Completar resolución`}
               </Button>
            </Box>
         ) : null}
      </Box>
   );
};
